import { StyleSheet, Font } from '@react-pdf/renderer';

import openSansRegular from '../assets/fonts/open-sans-v18-latin-regular.woff';
import openSansMedium from '../assets/fonts/open-sans-v18-latin-600.woff';
import openSansSemiBold from '../assets/fonts/open-sans-v18-latin-700.woff';
import openSansBold from '../assets/fonts/open-sans-v18-latin-800.woff';
import openSansLight from '../assets/fonts/open-sans-v18-latin-300.woff';

Font.register({
  family: 'Open Sans',
  fonts: [
    { src: openSansBold, fontStyle: 'normal', fontWeight: 'bold' },
    { src: openSansLight, fontStyle: 'normal', fontWeight: 'light' },
    { src: openSansMedium, fontStyle: 'normal', fontWeight: 'medium' },
    { src: openSansRegular, fontStyle: 'normal', fontWeight: 'regular' },
    { src: openSansSemiBold, fontStyle: 'normal', fontWeight: 'semibold' }
  ]
});

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff',
    padding: 60,
    fontSize: 12,
    lineHeight: 2,
    fontFamily: 'Open Sans'
  },
  // flex
  dFlex: {
    flexDirection: 'row',
    flexWrap: 'nowrap'
  },
  felxBetween: {
    justifyContent: 'space-between'
  },
  flexEnd: {
    justifyContent: 'flex-end'
  },
  // text
  textDefaultSize: {
    fontSize: 10
  },
  textBold: {
    fontWeight: 'medium'
  },
  textRight: {
    textAlign: 'right'
  },
  textCenter: {
    textAlign: 'center'
  },
  textLeft: {
    textAlign: 'left'
  },
  textUppercase: {
    textTransform: 'uppercase'
  },
  textGrey: {
    color: '#8b8b8b'
  },
  text12: {
    fontSize: 12
  },
  textCapitalize: {
    textTransform: 'capitalize'
  },
  // headers
  h1: {
    fontSize: 28,
    fontWeight: 400
  },
  h3: {
    fontSize: 13
  },
  // margin bottom
  mb1: {
    marginBottom: 2.625
  },
  mb2: {
    marginBottom: 5.25
  },
  mb3: {
    marginBottom: 10.5
  },
  mb4: {
    marginBottom: 15.75
  },
  mb5: {
    marginBottom: 31.5
  },
  // margin top
  mt1: {
    marginTop: 2.625
  },
  mt2: {
    marginTop: 5.25
  },
  mt3: {
    marginTop: 10.5
  },
  mt4: {
    marginTop: 15.75
  },
  mt5: {
    marginTop: 31.5
  },
  // margin left
  ml1: {
    marginLeft: 2.625
  },
  ml2: {
    marginLeft: 5.25
  },
  ml3: {
    marginLeft: 10.5
  },
  ml4: {
    marginLeft: 15.75
  },
  ml5: {
    marginLeft: 31.5
  },
  // margin right
  mr1: {
    marginRight: 2.625
  },
  mr2: {
    marginRight: 5.25
  },
  mr3: {
    marginRight: 10.5
  },
  mr4: {
    marginRight: 15.75
  },
  mr5: {
    marginRight: 31.5
  },
  // margin
  m1: {
    margin: 2.625
  },
  m2: {
    margin: 5.25
  },
  m3: {
    margin: 10.5
  },
  m4: {
    margin: 15.75
  },
  m5: {
    margin: 31.5
  },
  // padding top
  pt1: {
    paddingTop: 2.625
  },
  pt2: {
    paddingTop: 5.25
  },
  pt3: {
    paddingTop: 10.5
  },
  pt4: {
    paddingTop: 15.75
  },
  pt5: {
    paddingTop: 21
  },
  // padding bottom
  pb1: {
    paddingBottom: 2.625
  },
  pb2: {
    paddingBottom: 5.25
  },
  pb3: {
    paddingBottom: 10.5
  },
  pb4: {
    paddingBottom: 15.75
  },
  pb5: {
    paddingBottom: 21
  },
  // padding left
  pl1: {
    paddingLeft: 2.625
  },
  pl2: {
    paddingLeft: 5.25
  },
  pl3: {
    paddingLeft: 10.5
  },
  pl4: {
    paddingLeft: 15.75
  },
  pl5: {
    paddingLeft: 21
  },
  // padding right
  pr1: {
    paddingRight: 2.625
  },
  pr2: {
    paddingRight: 5.25
  },
  pr3: {
    paddingRight: 10.5
  },
  pr4: {
    paddingRight: 15.75
  },
  pr5: {
    paddingRight: 21
  },
  // padding
  p1: {
    padding: 2.625
  },
  p2: {
    padding: 5.25
  },
  p3: {
    padding: 10.5
  },
  p4: {
    padding: 15.75
  },
  p5: {
    padding: 21
  },
  // borders
  borderNone: {
    border: 'none'
  },
  // width
  w20: {
    width: '20%'
  },
  w25: {
    width: '25%'
  },
  wThird: {
    width: '33.3333333%'
  },
  w40: {
    width: '40%'
  },
  w50: {
    width: '50%'
  },
  wTwoThirds: {
    width: '66.6666666%'
  },
  w60: {
    width: '60%'
  },
  w75: {
    width: '75%'
  },
  w80: {
    width: '80%'
  },
  w100: {
    width: '100%'
  }
});

export default styles;
