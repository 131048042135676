import React, { useState } from 'react';
import { IntlContextConsumer, changeLocale } from 'gatsby-plugin-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup } from 'shards-react';

const languageName = {
  en: 'EN',
  fr: 'FR'
};

const Language = () => {
  const [expand, toggleExpand] = useState(false);
  return (
    <div className="">
      <FontAwesomeIcon icon="globe-africa" className="mr-2" />
      {(expand && (
        <ButtonGroup>
          <IntlContextConsumer>
            {({ languages, language: currentLocale }) => languages.map((language) => (
              <Button
                size="sm"
                theme={currentLocale === language ? 'dark' : 'light'}
                onClick={() => {
                  changeLocale(language);
                  toggleExpand(false);
                }}
              >
                {languageName[language]}
              </Button>
            ))}
          </IntlContextConsumer>
        </ButtonGroup>
      )) || (
        <IntlContextConsumer>
          {({ language: currentLocale }) => (
            <Button
              size="sm"
              className="px-0"
              theme=""
              onClick={() => {
                toggleExpand(true);
              }}
            >
              {languageName[currentLocale]}
            </Button>
          )}
        </IntlContextConsumer>
      )}
    </div>
  );
};

export default Language;
