import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby-plugin-intl';

import Language from './language';
import LightSwitch from './lightSwitch';
import Image from './image';
import { useWindowDimensions } from '../utils';
import logo from '../assets/images/logo.png';
import icon from '../assets/images/icon.png';

const Header = () => {
  const { width } = useWindowDimensions();
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const enable = false;

  useEffect(() => {
    setIsSmallScreen(width < 576);
  }, [width]);

  return (
    <div className="abd-navbar d-flex align-items-center justify-content-center">
      <div className="abd-navbar-content d-flex justify-content-between">
        <Link to="/">
          <Image
            src={isSmallScreen ? icon : logo}
            alt="ABD Logo"
            className="m-0"
          />
        </Link>
        <div className="d-flex align-items-center">
          {enable && <LightSwitch />}
          <Language />
        </div>
      </div>
    </div>
  );
};

export default Header;
