import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'shards-ui/dist/css/shards.min.css';
import { Container } from 'shards-react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Preloader, Puff } from 'react-preloader-icon';

import Header from './header';
import Footer from './footer';
import '../assets/styles/index.css';
import './icons';

const Layout = ({ children, className }) => {
  const [didMount, setDidMount] = useState(false);

  useEffect(() => {
    if (!didMount) {
      const timer = setTimeout(() => {
        setDidMount(true);
      }, 1000);
      return () => clearTimeout(timer);
    }
    return null;
  }, []);

  return (
    <div className="layout-container">
      <div
        className={`custom-preloader d-flex justify-content-center align-items-center ${
          didMount ? 'fade-out hide-preloader' : ''
        }`}
      >
        <Preloader
          use={Puff}
          size={60}
          strokeWidth={6}
          strokeColor="#31aa8c"
          duration={2000}
        />
      </div>
      <Header />
      <Container>
        <div
          style={{
            margin: '0 auto',
            maxWidth: 1200,
            paddingTop: '128px'
          }}
          className={className}
        >
          <>{children}</>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
