import { defineMessages } from 'react-intl';

export const scope = 'components.footer';

export default defineMessages({
  createdBy: {
    id: `${scope}.createdBy`,
    defaultMessage: 'Créé par'
  }
});
