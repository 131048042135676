import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useWindowDimensions } from '../utils';
import Image from './image';
import logo from '../assets/images/logo.png';
import icon from '../assets/images/icon.png';
import messages from '../translations/footer';

const Footer = () => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  const intl = useIntl();

  const siteName = data.site.siteMetadata.title;

  const { width } = useWindowDimensions();
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    setIsSmallScreen(width < 576);
  }, [width]);

  return (
    <div className="abd-footer d-flex align-items-center justify-content-center">
      <div className="abd-footer-content d-flex align-items-center justify-content-between">
        <div>
          <Image
            src={isSmallScreen ? icon : logo}
            alt="ABD Logo"
            className="m-0"
          />
        </div>
        <div className="mx-5 text-center">
          {`© ${new Date().getFullYear()} ${siteName} - ${intl.formatMessage(
            messages.createdBy
          )} `}
          <a href="https://eightsh.one">Eightsh One</a>
        </div>
        <div className="flex-row">
          <a
            href="https://www.linkedin.com/company/alternative-business-domiciliation/"
            target="blank"
          >
            <FontAwesomeIcon icon={['fab', 'linkedin-in']} className="mr-2" />
          </a>
          <a href="https://www.instagram.com/abdomiciliation" target="blank">
            <FontAwesomeIcon icon={['fab', 'instagram']} className="mr-2" />
          </a>
          <a href="https://www.facebook.com/ABDomiciliation" target="blank">
            <FontAwesomeIcon icon={['fab', 'facebook-f']} className="mr-0" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
