import React from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import moment from 'moment';
import 'moment/locale/fr';

import styles from './PDFStyles';

// Create Document Component
const Contract = props => {
  moment.locale('fr');
  moment.updateLocale('fr', {
    months: [
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juilet',
      'Août',
      'Septembre',
      'Octobre',
      'Novembre',
      'Décembre'
    ]
  });
  const { values } = props;
  let packName;
  let degree;
  switch (values.pack) {
    case 'pro-pack':
      packName = 'Pro Pack';
      degree = 2;
      break;
    case 'premium-pack':
      packName = 'Premium Pack';
      degree = 3;
      break;
    default:
      packName = 'Starter Pack';
      degree = 1;
  }
  let repTitle;
  if (values.wip) {
    // for wip
    if (values.companyType === 'SA' || values.type === 'association') {
      if (values.identifer === 'mr') {
        repTitle = 'son fondateur';
      } else {
        repTitle = 'sa fondatrice';
      }
    } else if (
      values.companyType === 'SARL' ||
      values.companyType === 'SUARL'
    ) {
      repTitle = 'son Gérant';
    }
  } else if (!values.wip) {
    // for non wip
    if (values.companyType === 'SA') {
      repTitle = 'son Directeur Général';
    } else if (
      values.companyType === 'SARL' ||
      values.companyType === 'SUARL'
    ) {
      repTitle = 'son Gérant';
    } else if (values.type === 'association') {
      repTitle = 'son président';
    }
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <Text style={{ ...styles.textCenter, ...styles.textBold }}>
            Contrat de domiciliation
          </Text>
          <Text> </Text>
          <Text> </Text>
          <Text>Entre les soussignés:</Text>
          <View style={styles.pl3}>
            <Text>
              La société ALTERNATIVE BUSINESS DOMICILIATION SARL ayant le IU
              1665238Y, au capital social de 2 000 Dinars, ayant son siège
              social sis à Résidence Dorret El Bouhayra, Villa n°2, Lac 2, 1053,
              Tunis, représentée par son Gérant Monsieur Anis BORCHANI.
            </Text>
          </View>
          <Text>Ci-après le «Domiciliataire»,</Text>
          <Text>D’une part,</Text>
          <Text>Et</Text>
          <View style={styles.pl3}>
            <Text>{` ${
              values.type === 'company' ? 'La société' : "L'association"
            } ${values.businessName}, une ${
              values.type === 'company'
                ? `société ${values.companyType}`
                : 'association'
            } ${
              values.wip
                ? 'en cours de constitution'
                : `au capital de ${values.capital} dinars, ayant l’Identifiant Unique N° ${values.uid}, dont le siège social ${values.businessAddress}, `
            } représentée par ${repTitle} ${
              values.identifer === 'mr' ? 'Mr' : 'Mme'
            } ${values.clientName} ${
              values.wip
                ? `titulaire de la CIN ${values.idCard} délivrée le ${moment(
                    values.idCardDate
                  )
                    .locale('fr')
                    .format('DD MMMM YYYY')} et domicile à ${values.address}`
                : ''
            }.`}</Text>
          </View>
          <Text>Ci-après le «Domicilié».</Text>
          <Text style={{ ...styles.textBold }}>
            IL A ETE CONVENU CE QUI SUIT:
          </Text>
          <Text style={{ ...styles.textBold }}>Article 1 - Objet</Text>
          <Text>
            Le présent contrat a pour objet la domiciliation du siège social du
            Domicilié.
          </Text>
          <Text>
            Le Domicilié a choisi de bénéficier des services suivants du
            Domiciliataire en optant pour le Pack {packName}:
          </Text>
          <Text>
            {degree === 1
              ? '- La domiciliation et la réception et la mise à disposition du courrier.'
              : ''}
          </Text>
          <Text>
            {degree === 2
              ? '- La domiciliation et la réexpédition du courrier par voie postale vers une adresse prédéfinie.'
              : ''}
          </Text>
          <Text>
            {degree === 3
              ? '- La domiciliation, la réexpédition du courrier par voie postale et la transmission instantanée du courrier par internet.'
              : ''}
          </Text>
          <Text style={{ ...styles.textBold }}>Article 2</Text>
          <Text>
            Le Domiciliataire s’oblige à informer l’administration fiscale et la
            caisse nationale à l’expiration du contrat ou en cas de résiliation
            de celui-ci, de la cessation de la domiciliation de l’entreprise
            dans ces locaux.
          </Text>
          <Text style={{ ...styles.textBold }}>
            Article 3 - Obligations du Domiciliataire
          </Text>
          <Text>
            Pendant toute la durée du présent contrat, le Domiciliataire
            s&apos;engage à :
          </Text>
          <Text>
            - être immatriculé au Registre National des Entreprises ou au
            Référentiel Tunisien des Métiers et des Compétences;
          </Text>
          <Text>
            - ne traiter en aucun cas des affaires du « Domicilié » qui reste
            seul responsable des actes qu’il effectuera, dégageant pour le
            présent et pour l’avenir, la responsabilité totale du « Domiciliant
            »;
          </Text>
          <Text>
            - informer l’administration fiscale et la caisse nationale de
            sécurité sociale, à l&apos;expiration du contrat ou, en cas de
            résiliation de celui-ci, de la cessation de la domiciliation de
            l&apos;entreprise dans ses locaux, ou également si le Domicilié n’a
            pas pris connaissance de son courrier depuis trois mois;
          </Text>
          <Text>
            - fournir chaque trimestre au centre des impôts et aux organismes de
            recouvrement des cotisations et contributions de sécurité sociale
            compétents, une liste des personnes et des sociétés qui se sont
            domiciliées dans ses locaux au cours de cette période, ou qui ont
            mis fin à leur domiciliation ainsi que chaque année, avant les
            quinze janviers, une liste des personnes et des sociétés domiciliées
            au 1er janvier.
          </Text>
          <Text style={{ ...styles.textBold }}>
            Article 4 - Obligations du Domicilié
          </Text>
          <Text>
            Le Domicilié s&apos;engage à utiliser effectivement et exclusivement
            les locaux susvisés pour son siège social et à informer le
            Domiciliataire de toute modification de son activité, de sa forme
            juridique, de son objet et de l&apos;identité des personnes ayant le
            pouvoir de l&apos;engager.
          </Text>
          <Text>
            Le « Domicilié » s’interdit expressément la sous-location sous
            toutes ses formes de tout ou partie des locaux mis à sa disposition
            aux conditions stipulées ci-avant.
          </Text>
          <Text style={{ ...styles.textBold }}>Article 5 - Mandat</Text>
          <Text>
            Le Domicilié donne mandat au Domiciliataire de recevoir, en son nom,
            toute notification.
          </Text>
          <Text style={{ ...styles.textBold }}>Article 6 - Durée</Text>
          <Text>
            La présente domiciliation est consentie qui commence le{' '}
            {moment(values.startDate).locale('fr').format('DD MMMM YYYY')} et
            fini le{' '}
            {moment(values.startDate)
              .add(1, 'y')
              .subtract(1, 'd')
              .locale('fr')
              .format('DD MMMM YYYY')}{' '}
            pour une durée d’une année.
          </Text>
          <Text>
            À l&apos;expiration du présent contrat ou en cas de résiliation de
            celui-ci, et conformément à l&apos;article 3, le Domiciliataire
            s&apos;engage à informer le Registre National des Entreprises de la
            cessation de la domiciliation.
          </Text>
          <Text>
            Il sera ensuite renouvelé, par tacite reconduction, sauf résiliation
            notifiée par l&apos;une ou l&apos;autre des parties par lettre
            recommandée avec accusé de réception et expédiée au moins 3 mois
            avant le terme fixé.
          </Text>
          <Text style={{ ...styles.textBold }}>Article 7 - Redevance</Text>
          <Text>
            {degree === 1
              ? 'La présente domiciliation est consentie et acceptée moyennant une redevance annuelle de Huit Cent Vingt-Huit DT HTVA payable d’avance au domicile du Domiciliataire en rajoutant la TVA au taux de 19% (157,320 DT) soit Neuf Cent Quatre-Vingt-Cinq Dinars et 320 Millimes TTC par an'
              : ''}
            {degree === 2
              ? 'La présente domiciliation est consentie et acceptée moyennant une redevance annuelle de Mille Cent Quatre-Vingt-Huit DT HTVA payable d’avance au domicile du Domiciliataire en rajoutant la TVA au taux de 19% (225,720 DT) soit Mille Quatre Cent Treize Dinars et 720 Millimes TTC par an.'
              : ''}
            {degree === 3
              ? 'La présente domiciliation est consentie et acceptée moyennant une redevance annuelle de Mille Quatre Cent Vingt-Huit DT HTVA payable d’avance au domicile du Domiciliataire en rajoutant la TVA au taux de 19% (271,320 DT) soit Mille Six Cent Quatre-Vingt-Dix-Neuf Dinars et 320 Millimes TTC par an.'
              : ''}
          </Text>
          <Text style={{ ...styles.textBold }}>
            Article 8 – Clause de confidentialité
          </Text>
          <Text>
            Le Domiciliataire est tenu de ne pas divulguer les informations
            auxquelles elle aura pu avoir accès, dans le cadre de
            l&apos;exécution de sa mission. L&apos;attention du prestataire peut
            être attirée par la confidentialité des documents en cause, lorsque,
            notamment, ces derniers sont revêtus de la mention
            &quot;confidentiel&quot;.
          </Text>
          <Text>
            Le Domiciliataire, toutefois, ne saurait être tenu pour responsable
            d&apos;aucune divulgation si les éléments divulgués étaient dans le
            domaine public à la date de la divulgation, ou s&apos;il en avait
            connaissance, ou les obtenait de tiers par des moyens légitimes.
          </Text>
          <Text style={{ ...styles.textBold }}>
            Article 9 - Attribution de juridiction
          </Text>
          <Text>
            Chaque partie élit domicile en son siège social. Toutes
            contestations seront résolues à l’amiable. A défaut, il est attribué
            compétence au tribunal du commerce de Tunis.
          </Text>
          <Text> </Text>
          <Text>
            Fait à Tunis, le {moment().locale('fr').format('DD MMMM YYYY')}
          </Text>
          <Text> </Text>
        </View>
        <View style={{ ...styles.dFlex }}>
          <View style={{ ...styles.w50 }}>
            <Text style={{ ...styles.textCenter }}>Le « Domiciliataire »</Text>
          </View>
          <View style={{ ...styles.w50 }}>
            <Text style={{ ...styles.textCenter }}> Le « Domicilié »</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default Contract;
