import createSelector from '../utils/createSelector';

import { initialState } from './reducer';

export const selectApp = state => state.app || initialState;

export const selectIsDarkMode = createSelector(
  selectApp,
  state => state.isDarkMode
);

export const selectPack = createSelector(selectApp, state => state.pack);
