import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faGlobeAfrica,
  faSun,
  faMoon,
  faArrowDown
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn
} from '@fortawesome/free-brands-svg-icons';

library.add(
  faGlobeAfrica,
  faSun,
  faMoon,
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faArrowDown
);
